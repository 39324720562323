<template>
  <el-descriptions title="" :column="1" border>
    <el-descriptions-item label="Token ID" label-align="right">
      {{ tokenData.tokenID }}
      <el-button
        type="primary"
        size="small"
        :icon="TopRight"
        @click="goToOpensea()"
      >
        See on OpenSea
      </el-button>
    </el-descriptions-item>
    <el-descriptions-item
      v-for="attr in tokenAttributes"
      :key="attr.trait_type"
      :label="formatAtrributeName(attr.trait_type)"
      label-align="right"
    >
      {{ attr.value }}
    </el-descriptions-item>
    <el-descriptions-item
      label="Eligible for frases minting"
      label-align="right"
    >
      {{ frasesMintable }}
    </el-descriptions-item>
  </el-descriptions>
</template>
<script>
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { defineComponent, watch, ref, computed, onBeforeMount } from 'vue'
  import { Search, TopRight } from '@element-plus/icons'
  import FrasesContractService from '@/services/FrasesContractService'
  const openseaAssetsUrl =
    process.env.VUE_APP_OPENSEA_ASSETS_URL || 'https://opensea.io/assets'

  export default defineComponent({
    props: ['tokenData', 'projectSlug', 'contractAddress'],
    setup(props) {
      const router = useRouter()
      const store = useStore()
      const {
        state: { contractState, root },
      } = store
      const web3 = computed(() => contractState.web3)
      const frasesContractAddress = computed(
        () =>
          root.projects.find((p) => p.name == 'Frases').configuration
            .contractAddress
      )
      const frasesContractABI = computed(
        () =>
          root.projects.find((p) => p.name == 'Frases').configuration
            .contractABI
      )
      let frasesContractService
      const frasesMintable = ref('')
      const tokenAttributes = ref([])
      const goToOpensea = () => {
        window
          .open(
            `${openseaAssetsUrl}/${props.contractAddress}/${props.tokenData.tokenID}`,
            '_blank'
          )
          .focus()
      }
      onBeforeMount(async () => {
        if (web3.value) {
          const frasesContract = new web3.value.eth.Contract(
            JSON.parse(frasesContractABI.value),
            frasesContractAddress.value
          )
          frasesContractService = new FrasesContractService(
            web3.value,
            frasesContract
          )
          const claimed = await frasesContractService.claimedPalabrasTokens(
            props.tokenData.tokenID.toString()
          )

          frasesMintable.value = claimed ? 'No' : 'Yes'
        }

        tokenAttributes.value = JSON.parse(props.tokenData.jsonData).attributes
        setAttributeIndex('word', 0)
        setAttributeIndex('round', 1)
        setAttributeIndex('type', 2)
      })
      watch([web3], async () => {
        if (web3.value) {
          const frasesContract = new web3.value.eth.Contract(
            JSON.parse(frasesContractABI.value),
            frasesContractAddress.value
          )
          frasesContractService = new FrasesContractService(
            web3.value,
            frasesContract
          )
          const claimed = await frasesContractService.claimedPalabrasTokens(
            props.tokenData.tokenID.toString()
          )

          frasesMintable.value = claimed ? 'No' : 'Yes'
        }
      })

      const setAttributeIndex = (attrStr, toIndex) => {
        const attr = tokenAttributes.value.find(
          (a) => a.trait_type.toLowerCase() == attrStr
        )
        const attrFromIndex = tokenAttributes.value.indexOf(attr)
        const attrElement = tokenAttributes.value.splice(attrFromIndex, 1)[0]
        tokenAttributes.value.splice(toIndex, 0, attrElement)
      }

      const formatAtrributeName = (attribute) => {
        return attribute
          .toLowerCase()
          .split('_')
          .join(' ')
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
          .join(' ')
      }

      const goToGallery = () => {
        const { mode, style } = props.tokenData
        router.push({
          path: `/tokens/${props.projectSlug}`,
          query: {
            sorting: 1,
            mode: mode,
            style: style,
          },
        })
      }

      return {
        frasesMintable,
        tokenAttributes,
        Search,
        TopRight,
        goToOpensea,
        goToGallery,
        formatAtrributeName,
      }
    },
  })
</script>
<style scoped>
  .copy-to-clipboard {
    cursor: pointer;
  }
</style>
