<template>
  <el-descriptions title="" :column="1">
    <el-descriptions-item
      label="Iterations:"
      label-class-name="el-descriptions__title"
      label-align="right"
    >
      <el-link
        v-for="item in tokenData.history"
        :key="item.iteration"
        :href="`${item.tokenID}?iteration=${item.iteration}`"
        :underline="false"
        :disabled="item.iteration == selectedIteration"
      >
        <el-button
          class="iteration-btn"
          type="primary"
          size="small"
          :disabled="item.iteration == selectedIteration"
          circle
        >
          {{ item.iteration }}
        </el-button>
      </el-link>
    </el-descriptions-item>
  </el-descriptions>
</template>
<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: ['tokenData', 'selectedIteration'],
    components: {},
    setup() {
      return {}
    },
  })
</script>
<style scoped>
  .iteration-btn {
    margin: 0px 2px 0px 2px;
  }
</style>
