<template>
  <el-button
    v-if="!isTokenOwner"
    type="primary"
    size="default"
    @click="createToken"
    style="display: inline !important"
  >
    Create alike
    <el-icon class="el-icon--right"><BrushFilled /></el-icon>
  </el-button>
</template>
<script>
  import { defineComponent } from 'vue'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { BrushFilled } from '@element-plus/icons'

  export default defineComponent({
    props: ['isTokenOwner', 'projectSlug', 'tokenData'],
    components: {
      BrushFilled,
    },
    setup(props) {
      const router = useRouter()
      const store = useStore()
      const {
        dispatch,
        state: { user },
      } = store

      const createToken = () => {
        if (props.tokenData.mode === '4') {
          if (
            user.whitelistedEarly ||
            user.whitelistedHolder ||
            user.whitelistedPrime
          ) {
            dispatch('setMode', props.tokenData.mode)
          } else {
            dispatch('setNotifyWhitelistedSettings', true)
            dispatch('setMode', '1')
          }
        } else {
          dispatch('setMode', props.tokenData.mode)
        }
        if (props.tokenData.style === '4') {
          if (
            user.whitelistedEarly ||
            user.whitelistedHolder ||
            user.whitelistedPrime
          ) {
            dispatch('setStyle', props.tokenData.style)
          } else {
            dispatch('setStyle', '1')
            dispatch('setNotifyWhitelistedSettings', true)
          }
        } else if (props.tokenData.style === '5') {
          if (user.whitelistedEarly) {
            dispatch('setStyle', props.tokenData.style)
          } else {
            dispatch('setStyle', '1')
            dispatch('setNotifyWhitelistedSettings', true)
          }
        } else if (props.tokenData.style === '6') {
          if (user.whitelistedPrime) {
            dispatch('setStyle', props.tokenData.style)
          } else {
            dispatch('setStyle', '1')
            dispatch('setNotifyWhitelistedSettings', true)
          }
        } else {
          dispatch('setStyle', props.tokenData.style)
        }

        dispatch('setVar1', props.tokenData.var1)
        dispatch('setVar2', props.tokenData.var2)

        dispatch('setBackground', props.tokenData.blackBackground)
        dispatch('setShowAddress', props.tokenData.displayAddress)

        router.push({ path: `/${props.projectSlug}` })
      }

      return {
        createToken,
      }
    },
  })
</script>
