<template>
  <el-alert
    v-if="hasUpdated"
    :closable="false"
    effect="dark"
    type="success"
    show-icon
  >
    <h2>
      Please wait a couple of minutes and refresh your browser to see your new
      token artwork and iteration below.
    </h2>
  </el-alert>
</template>
<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: ['hasUpdated'],
    components: {},
    setup() {
      return {}
    },
  })
</script>
