<template>
  <el-descriptions title="" :column="1" border>
    <el-descriptions-item label="Token ID" label-align="right">
      {{ tokenData.tokenID }}
      <el-button
        type="primary"
        size="small"
        :icon="TopRight"
        @click="goToOpensea()"
      >
        See on OpenSea
      </el-button>
    </el-descriptions-item>
    <el-descriptions-item label="Creator address" label-align="right">
      <span
        class="copy-to-clipboard"
        @click="copyToClipboard(tokenData.creatorAddress)"
      >
        {{ formatAddress(tokenData.creatorAddress) }}
        <el-icon>
          <CopyDocument />
        </el-icon>
      </span>
    </el-descriptions-item>
    <el-descriptions-item label="Mode" label-align="right">
      {{ MODES[tokenData.mode].name }}
    </el-descriptions-item>
    <el-descriptions-item label="Style" label-align="right">
      {{ MODES[tokenData.mode].styles[tokenData.style].name }}
      <el-button
        type="primary"
        size="small"
        :icon="Search"
        @click="goToGallery"
      >
        Browse this style
      </el-button>
    </el-descriptions-item>
    <el-descriptions-item
      :label="modeName(tokenData.mode, tokenData.style)"
      label-align="right"
    >
      {{ tokenData.var1 }}
    </el-descriptions-item>
    <el-descriptions-item
      :label="styleName(tokenData.mode, tokenData.style)"
      label-align="right"
    >
      {{ tokenData.var2 }}
    </el-descriptions-item>
    <el-descriptions-item label="Background" label-align="right">
      {{ tokenData.blackBackground ? 'Black' : 'White' }}
    </el-descriptions-item>
    <el-descriptions-item label="Display address" label-align="right">
      {{ tokenData.displayAddress ? 'Yes' : 'No' }}
    </el-descriptions-item>
  </el-descriptions>
</template>
<script>
  import { MODES } from '@/constants/toolPanelConstants'
  import { useRouter } from 'vue-router'
  import { copyToClipboard, formatAddress } from '@/utils/utils'
  import { defineComponent } from 'vue'
  import { CopyDocument, Search, TopRight } from '@element-plus/icons'
  const openseaAssetsUrl =
    process.env.VUE_APP_OPENSEA_ASSETS_URL || 'https://opensea.io/assets'

  export default defineComponent({
    props: ['tokenData', 'projectSlug', 'contractAddress'],
    components: {
      CopyDocument,
    },
    setup(props) {
      const router = useRouter()
      const goToOpensea = () => {
        window
          .open(
            `${openseaAssetsUrl}/${props.contractAddress}/${props.tokenData.tokenID}`,
            '_blank'
          )
          .focus()
      }

      const modeName = (mode, style) => {
        let modeName

        if (MODES[mode].styles[style].vars) {
          modeName = MODES[mode].styles[style].vars['1']
        } else if (MODES[mode].vars) {
          modeName = MODES[mode].vars['1']
        } else {
          modeName = 'Size'
        }

        return modeName
      }

      const styleName = (mode, style) => {
        let styleName

        if (MODES[mode].styles[style].vars) {
          styleName = MODES[mode].styles[style].vars['2']
        } else if (MODES[mode].vars) {
          styleName = MODES[mode].vars['2']
        } else {
          styleName = 'Density'
        }

        return styleName
      }

      const goToGallery = () => {
        const { mode, style } = props.tokenData
        router.push({
          path: `/tokens/${props.projectSlug}`,
          query: {
            sorting: 1,
            mode: mode,
            style: style,
          },
        })
      }

      return {
        MODES,
        Search,
        TopRight,
        copyToClipboard,
        formatAddress,
        goToOpensea,
        goToGallery,
        modeName,
        styleName,
      }
    },
  })
</script>
<style scoped>
  .copy-to-clipboard {
    cursor: pointer;
  }
</style>
