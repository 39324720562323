<template>
  <div class="tip custom-block" v-if="isTokenOwner">
    <p class="custom-block-title">You own this token</p>
    <p>
      Congratulations! As an owner, you can update your token and refresh its
      artwork. Updates are currently free of charge (small gas fees apply). Some
      styles also mutate on updates. Click Update to explore your creative
      options.
    </p>
    <el-button type="primary" size="default" @click="updateToken">
      Update
      <el-icon class="el-icon--right"><Edit /></el-icon>
    </el-button>
  </div>
</template>
<script>
  import { defineComponent } from 'vue'
  import { Edit } from '@element-plus/icons'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'

  export default defineComponent({
    props: ['isTokenOwner', 'projectSlug', 'tokenData'],
    components: {
      Edit,
    },
    setup(props) {
      const router = useRouter()
      const store = useStore()
      const { dispatch } = store

      const updateToken = () => {
        //set iteration for update
        dispatch('setIteration', Number.parseInt(props.tokenData.iteration) + 1)
        router.push({
          path: `/tokens/${props.projectSlug}/${props.tokenData.tokenID}/edit`,
        })
      }

      return {
        updateToken,
      }
    },
  })
</script>
<style scoped>
  .custom-block.tip {
    padding: 8px 16px;
    background-color: var(--el-color-primary-light-9);
    border-radius: 4px;
    border-left: 5px solid var(--el-color-primary);
    margin-bottom: 20px;
  }

  .custom-block .custom-block-title {
    font-weight: 700;
  }

  .custom-block p:not(.custom-block-title) {
    font-size: 1rem;
  }
</style>
