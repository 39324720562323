<template>
  <el-descriptions title="" :column="1" border>
    <el-descriptions-item label="Token ID" label-align="right">
      {{ tokenData.tokenID }}
      <el-button
        type="primary"
        size="small"
        :icon="TopRight"
        @click="goToOpensea()"
      >
        See on OpenSea
      </el-button>
    </el-descriptions-item>
    <el-descriptions-item label="Frase" label-align="right">
      {{ tokenData.palabras.join(' ').toUpperCase() }}
    </el-descriptions-item>
    <el-descriptions-item label="Artist" label-align="right">
      {{ tokenData.artist }}
    </el-descriptions-item>
  </el-descriptions>
</template>
<script>
  import { useRouter } from 'vue-router'
  import { defineComponent } from 'vue'
  import { Search, TopRight } from '@element-plus/icons'
  const openseaAssetsUrl =
    process.env.VUE_APP_OPENSEA_ASSETS_URL || 'https://opensea.io/assets'

  export default defineComponent({
    props: ['tokenData', 'projectSlug', 'contractAddress'],
    setup(props) {
      const router = useRouter()
      const goToOpensea = () => {
        window
          .open(
            `${openseaAssetsUrl}/${props.contractAddress}/${props.tokenData.tokenID}`,
            '_blank'
          )
          .focus()
      }
      const goToGallery = () => {
        const { mode, style } = props.tokenData
        router.push({
          path: `/tokens/${props.projectSlug}`,
          query: {
            sorting: 1,
            mode: mode,
            style: style,
          },
        })
      }

      return {
        Search,
        TopRight,
        goToOpensea,
        goToGallery,
      }
    },
  })
</script>
<style scoped>
  .copy-to-clipboard {
    cursor: pointer;
  }
</style>
