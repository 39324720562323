<template>
  <el-descriptions title="" :column="1" border>
    <el-descriptions-item label="Token ID" label-align="right">
      {{ tokenData.tokenID }}
      <el-button
        type="primary"
        size="small"
        :icon="TopRight"
        @click="goToOpensea()"
      >
        See on OpenSea
      </el-button>
    </el-descriptions-item>
    <el-descriptions-item
      v-for="attr in tokenAttributes"
      :key="attr.trait_type"
      :label="formatAtrributeName(attr.trait_type)"
      label-align="right"
    >
      {{ attr.value }}
    </el-descriptions-item>
  </el-descriptions>
</template>
<script>
  import { useRouter } from 'vue-router'
  import { defineComponent, ref, onBeforeMount } from 'vue'
  import { Search, TopRight } from '@element-plus/icons'
  const openseaAssetsUrl =
    process.env.VUE_APP_OPENSEA_ASSETS_URL || 'https://opensea.io/assets'

  export default defineComponent({
    props: ['tokenData', 'projectSlug', 'contractAddress'],
    setup(props) {
      const router = useRouter()
      const tokenAttributes = ref([])
      const goToOpensea = () => {
        window
          .open(
            `${openseaAssetsUrl}/${props.contractAddress}/${props.tokenData.tokenID}`,
            '_blank'
          )
          .focus()
      }
      onBeforeMount(async () => {
        tokenAttributes.value = JSON.parse(props.tokenData.jsonData).attributes
      })
      const formatAtrributeName = (attribute) => {
        return attribute
          .toLowerCase()
          .split('_')
          .join(' ')
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
          .join(' ')
      }

      const goToGallery = () => {
        const { mode, style } = props.tokenData
        router.push({
          path: `/tokens/${props.projectSlug}`,
          query: {
            sorting: 1,
            mode: mode,
            style: style,
          },
        })
      }

      return {
        tokenAttributes,
        Search,
        TopRight,
        goToOpensea,
        goToGallery,
        formatAtrributeName,
      }
    },
  })
</script>
<style scoped>
  .copy-to-clipboard {
    cursor: pointer;
  }
</style>
