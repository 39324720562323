<template>
  <el-button type="primary" size="default" @click="openImage">
    Open Image
    <el-icon class="el-icon--right"><Finished /></el-icon>
  </el-button>
</template>
<script>
  import { defineComponent } from 'vue'
  import { Finished } from '@element-plus/icons'

  export default defineComponent({
    props: ['tokenData', 'iteration'],
    components: {
      Finished,
    },
    setup(props) {
      const openImage = () => {
        window.open(`${props.tokenData.image}`, '_blank').focus()
      }

      return {
        openImage,
      }
    },
  })
</script>
