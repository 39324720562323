<template>
  <div class="token-page-container">
    <div v-if="!loading" class="main-container">
      <div v-if="!tokenNotFound">
        <div v-if="projectSlug === 'norte'">
          <TokenUpdateAlert :hasUpdated="hasUpdated" />
        </div>
        <el-row>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
            class="left-container"
          >
            <el-card
              style="margin: 3%; width: 90% !important"
              :body-style="{ padding: '10px' }"
            >
              <el-image
                :src="imageFileName(state.tokenData.image)"
                class="image"
                fit="contain"
                style="width: 100%; height: auto; aspect-ratio: 1"
              >
                <template #error>
                  <div
                    style="
                      width: 100%;
                      height: 0;
                      padding-top: 40%;
                      padding-bottom: 60%;
                      font-size: 20px;
                    "
                  >
                    <el-icon :size="50" style="width: 3em; height: 3em">
                      <full-screen style="width: 5em; height: 5em" />
                    </el-icon>
                    <br />Loading...
                  </div>
                </template>
              </el-image>
            </el-card>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
            class="right-container"
          >
            <div v-if="projectSlug === 'norte'" style="margin-top: 3%">
              <TokenUpdateTipBlock
                :isTokenOwner="isTokenOwner"
                :projectSlug="projectSlug"
                :tokenData="state.tokenData"
              />
            </div>
            <div style="margin: 10px 0px 10px 0px">
              <h3 class="title-text">
                {{ state.tokenData.name }}
              </h3>

              <p class="author-text">by {{ state.tokenData.artist }}</p>

              <p class="description-text">
                {{ state.tokenData.description }}
              </p>
              <div v-if="projectSlug === 'norte'">
                <TokenIterationsCounter
                  :tokenData="state.tokenData"
                  :selectedIteration="selectedIteration"
                />
              </div>
            </div>
            <div class="s1-token-action-btns">
              <div v-if="projectSlug === 'norte'">
                <NorteCreateAlike
                  :tokenData="state.tokenData"
                  :isTokenOwner="isTokenOwner"
                  :projectSlug="projectSlug"
                />
              </div>
              <TokenSeeLiveBtn
                v-if="state.tokenData.animation_url"
                :tokenData="state.tokenData"
                :iteration="iteration"
              />
              <TokenOpenImageBtn
                v-if="state.tokenData.image"
                :tokenData="state.tokenData"
              />
            </div>
            <div v-if="projectSlug === 'norte'">
              <NorteTokenDescriptions
                :tokenData="state.tokenData"
                :contractAddress="contractAddress"
                :projectSlug="projectSlug"
              />
            </div>
            <div v-else-if="projectSlug === 'palabras'">
              <PalabrasTokenDescriptions
                :tokenData="state.tokenData"
                :contractAddress="contractAddress"
                :projectSlug="projectSlug"
              />
            </div>
            <div v-else-if="projectSlug === 'frases'">
              <FrasesTokenDescriptions
                :tokenData="state.tokenData"
                :contractAddress="contractAddress"
                :projectSlug="projectSlug"
              />
            </div>
            <div v-else-if="projectSlug === 'base-gen-art'">
              <BaseGenArtTokenDescriptions
                :tokenData="state.tokenData"
                :contractAddress="contractAddress"
                :projectSlug="projectSlug"
              />
            </div>
            <div v-else-if="projectSlug === 'escaleras'">
              <EscalerasTokenDescriptions
                :tokenData="state.tokenData"
                :contractAddress="contractAddress"
                :projectSlug="projectSlug"
              />
            </div>
            <div v-else>
              <TokenDescriptions
                :tokenData="state.tokenData"
                :contractAddress="contractAddress"
              />
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-else>
        <el-result
          icon="warning"
          title="404"
          sub-title="We're sorry, we couldn't find that anywhere in the Studio."
        >
          <template #extra>
            <el-link href="/" :underline="false">
              <el-button type="primary" size="default">Back</el-button>
            </el-link>
          </template>
        </el-result>
      </div>
    </div>
  </div>
</template>

<script>
  import { useRoute, useRouter } from 'vue-router'
  import { getToken, isOwnerOf } from '@/services/TokenService'
  import { onBeforeMount, reactive, ref, computed, watch } from 'vue'
  import { useStore } from 'vuex'
  import { FullScreen } from '@element-plus/icons'

  import { ElNotification } from 'element-plus'
  import { NETWORK_IDS, NETWORK_NAMES } from '@/constants/walletConstants'
  import { selectProjectContract } from '@/services/ProjectService'

  import TokenDescriptions from '@/components/token/token-descriptions'
  import TokenSeeLiveBtn from '@/components/token/token-see-live'
  import TokenOpenImageBtn from '@/components/token/token-open-image'
  import TokenUpdateAlert from '@/components/token/update-alert'
  import TokenUpdateTipBlock from '@/components/token/update-tip-block'
  import TokenIterationsCounter from '@/components/token/iterations-counter'

  import NorteTokenDescriptions from '@/components/projects/norte/norte-token/token-descriptions'
  import NorteCreateAlike from '@/components/projects/norte/norte-token/token-create-alike'
  import PalabrasTokenDescriptions from '@/components/projects/palabras/palabras-token/token-descriptions'
  import FrasesTokenDescriptions from '@/components/projects/frases/frases-token/token-descriptions'
  import BaseGenArtTokenDescriptions from '@/components/projects/base-gen-art/base-gen-art-token/token-descriptions'
  import EscalerasTokenDescriptions from '@/components/projects/escaleras/escaleras-token/token-descriptions'

  export default {
    name: 'Token',
    components: {
      TokenDescriptions,
      TokenSeeLiveBtn,
      TokenOpenImageBtn,
      TokenIterationsCounter,
      TokenUpdateAlert,
      TokenUpdateTipBlock,
      NorteTokenDescriptions,
      NorteCreateAlike,
      PalabrasTokenDescriptions,
      FrasesTokenDescriptions,
      BaseGenArtTokenDescriptions,
      EscalerasTokenDescriptions,
      FullScreen,
    },
    setup() {
      const store = useStore()
      const {
        dispatch,
        state: { contractState, user, root },
      } = store

      const route = useRoute()
      const router = useRouter()
      const projectSlug = route.params.project_slug
      const tokenID = route.params.token_id
      const { notOwner, hasUpdated, iteration } = route.query
      const notificationTime = process.env.VUE_APP_NOTIFICATION_DURATION

      const walletAddress = computed(() => user.walletAddress)
      const contract = computed(() => contractState.contract)
      const web3 = computed(() => contractState.web3)

      const selectedIteration = ref('')
      const loading = ref(true)
      const tokenNotFound = ref(false)
      const isTokenOwner = ref(false)
      const jsonData = ref()
      const networkId = computed(() => user.networkId)
      const connectedToDesiredNetwork = ref(false)
      const desiredNetworkName = ref('')

      const contractAddress = computed(
        () =>
          root.projects.find((p) => p.projectSlug === projectSlug).configuration
            .contractAddress
      )
      const state = reactive({
        tokenData: {},
      })

      const imageFileName = (fileName) => {
        if (projectSlug == 'frases' && !fileName.includes('thumbnail')) {
          fileName = fileName.replace(/(\.[\w\d_-]+)$/i, '-thumbnail$1')
        }
        return fileName
      }

      onBeforeMount(async () => {
        if (web3.value) {
          await selectProjectContract(projectSlug, web3.value, dispatch)
          connectedToDesiredNetwork.value =
            networkId.value &&
            NETWORK_IDS[process.env.NODE_ENV] == networkId.value
          desiredNetworkName.value =
            NETWORK_NAMES[NETWORK_IDS[process.env.NODE_ENV]]
        }

        if (route.query) {
          if (notOwner) {
            ElNotification({
              title: 'Dear user',
              message:
                "You can't update this token because you're not the owner",
              type: 'warning',
              duration: notificationTime,
            })
          }
          router.replace({ query: {} })
        }

        if (
          walletAddress.value &&
          contract.value &&
          connectedToDesiredNetwork.value
        ) {
          isTokenOwner.value = await isOwnerOf(
            tokenID,
            walletAddress.value,
            contract.value
          )
        }

        const tokenResponse = await getToken(projectSlug, tokenID)
        if (tokenResponse.response) {
          const token = tokenResponse.response
          selectedIteration.value = iteration || token.iteration

          if (iteration) {
            state.tokenData = {
              ...token.history[token.history.length - parseInt(iteration)],
              history: token.history,
            }
          } else {
            state.tokenData = token
          }
        } else {
          tokenNotFound.value = true
        }
        jsonData.value = JSON.parse(state.tokenData.jsonData)
        loading.value = false
      })

      watch([web3], async () => {
        await selectProjectContract(projectSlug, web3.value, dispatch)
      })

      watch([walletAddress, contract, networkId], async () => {
        connectedToDesiredNetwork.value =
          networkId.value &&
          NETWORK_IDS[process.env.NODE_ENV] == networkId.value
        desiredNetworkName.value =
          NETWORK_NAMES[NETWORK_IDS[process.env.NODE_ENV]]

        if (
          walletAddress.value &&
          contract.value &&
          connectedToDesiredNetwork.value
        ) {
          isTokenOwner.value = await isOwnerOf(
            tokenID,
            walletAddress.value,
            contract.value
          )
        } else {
          isTokenOwner.value = false
        }
      })

      return {
        jsonData,
        selectedIteration,
        state,
        loading,
        tokenNotFound,
        isTokenOwner,
        hasUpdated,
        projectSlug,
        iteration,
        contractAddress,
        imageFileName,
      }
    },
  }
</script>
<style scoped>
  .title-text {
    margin-bottom: 0;
  }

  .description-text {
    font-size: 1rem;
    text-align: left;
  }

  .author-text {
    font-size: 0.8rem;
    padding-bottom: 10px;
  }

  .token-page-container {
    display: flex;
    align-items: top;
    justify-content: center;
  }

  .main-container {
    max-width: 1500px;
  }

  .left-container,
  .right-container {
    padding: 10px;
  }

  .iteration-link {
    margin-right: 10px;
  }

  .selected {
    font-weight: bolder;
  }

  .el-button {
    margin: 4px;
  }

  .s1-token-action-btns {
    margin: 10px 0px 20px 0px;
  }

  .s1-token-action-btns div {
    display: inline !important;
  }
</style>
