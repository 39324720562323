<template>
  <el-button type="primary" size="default" @click="seeLive">
    See Live
    <el-icon class="el-icon--right"><Finished /></el-icon>
  </el-button>
</template>
<script>
  import { defineComponent } from 'vue'
  import { Finished } from '@element-plus/icons'

  export default defineComponent({
    props: ['tokenData', 'iteration'],
    components: {
      Finished,
    },
    setup(props) {
      const seeLive = () => {
        let liveURL
        if (props.iteration) {
          liveURL = `${props.tokenData.animation_url}?iteration=${props.iteration}`
        } else {
          liveURL = props.tokenData.animation_url
        }
        window.open(`${liveURL}`, '_blank').focus()
      }

      return {
        seeLive,
      }
    },
  })
</script>
