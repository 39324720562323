<template>
  <el-descriptions title="" :column="1" border>
    <el-descriptions-item label="Token ID" label-align="right">
      {{ tokenData.tokenID }}
      <el-button
        type="primary"
        size="small"
        :icon="TopRight"
        @click="goToOpensea()"
      >
        See on OpenSea
      </el-button>
    </el-descriptions-item>
    <el-descriptions-item
      v-if="tokenData.creatorAddress"
      label="Creator address"
      label-align="right"
    >
      <span
        class="copy-to-clipboard"
        @click="copyToClipboard(tokenData.creatorAddress)"
      >
        {{ formatAddress(tokenData.creatorAddress) }}
        <el-icon>
          <CopyDocument />
        </el-icon>
      </span>
    </el-descriptions-item>
  </el-descriptions>
</template>
<script>
  import { copyToClipboard, formatAddress } from '@/utils/utils'
  import { defineComponent } from 'vue'
  import { CopyDocument, TopRight } from '@element-plus/icons'

  const openseaAssetsUrl =
    process.env.VUE_APP_OPENSEA_ASSETS_URL || 'https://opensea.io/assets'

  export default defineComponent({
    props: ['tokenData', 'contractAddress'],
    components: {
      CopyDocument,
    },
    setup(props) {
      const goToOpensea = () => {
        window
          .open(
            `${openseaAssetsUrl}/${props.contractAddress}/${props.tokenData.tokenID}`,
            '_blank'
          )
          .focus()
      }

      return {
        TopRight,
        copyToClipboard,
        formatAddress,
        goToOpensea,
      }
    },
  })
</script>
<style scoped>
  .copy-to-clipboard {
    cursor: pointer;
  }
</style>
